.o-list{
	$c: &;

	&--videos{
		overflow: hidden; overflow-y: auto; height: 100%;  position: relative;
		&::-webkit-scrollbar { width:  10px; height: 10px }
		&::-webkit-scrollbar-thumb { background: #888; border-radius: 0 }
		&::-webkit-scrollbar-track { background: #e2e4eb; border-radius:0 }

		#{$c}__content{ position: absolute; height: 100% }
	}
}