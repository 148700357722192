//include emoji
$emojis: false !default;

@mixin emoji($name, $position:'before') {

  @if($position == 'before'){
    &:before{ @extend %emoji, %emoji-#{$name} }
  }
  @else{
    &:after{ @extend %emoji, %emoji-#{$name} }
  }
}

@if($emojis){

  %emoji{
    text-transform: none; line-height: 1;
    font-style: normal; font-weight: normal; font-variant: normal; vertical-align: middle;
    -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
  }

  @each $emoji in $emojis {

    $id      : nth($emoji, 1);
    $content : nth($emoji, 2);

    %emoji-#{$id}{ content: $content }
  }
}


@mixin data-emoji {

  [data-emoji]:before, [data-emoji_after]:after,
  [emoji]:before, [emoji-after]:after{ @extend %emoji }

  @each $emoji in $emojis {

    $id      : nth($emoji, 1);
    $content : nth($emoji, 2);

    [data-emoji="#{$id}"]:before, [data-emoji_after="#{$id}"]:after,
    [emoji="#{$id}"]:before, [emoji-after="#{$id}"]:after{ @extend %emoji-#{$id} }
  }
}

@if (variable-exists(data-attributes) and index($data-attributes, 'emoji') and $emojis) {
  @include data-emoji;
}


