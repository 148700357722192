 .dialog-container,
 .dialog-overlay {
   position: fixed; /* 1 */
   top: 0; /* 1 */
   right: 0; /* 1 */
   bottom: 0; /* 1 */
   left: 0; /* 1 */
 }
 .dialog-container {
   z-index: 9998; /* 1 */
   display: flex; /* 2 */
 }

 .dialog-container[aria-hidden='true'] {
   display: none; /* 1 */
 }

#modal-title svg {
max-width: 100%;
  }
 .dialog-content {
   z-index: 2; /* 2 */
   position: relative; /* 2 */
   text-align: center;
 }

 .vn-overlay {
    background-color: rgba(0, 0, 0, 0.5);
 }

 .vn-video-size {
    display: block;
    overflow-y: hidden;
 }

 .ginput_container {
color: black !important;
}
#gform_submit_button_1 {
    border: white solid 1px;
    margin: auto;
}
