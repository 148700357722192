swiper{
	@include aspect-ratio(1000/563);
	@media #{$to-small} {
		@include aspect-ratio(768/1024);
	}
	*{ display: none }
}

.o-home-slider{
	position:relative; margin-left: -15px; margin-right: -15px;

	@media #{$from-small}{ margin-left: 0; margin-right: 0 }

	&__progress{
		position: absolute; z-index: 1; left: 0; width: 100%; top: auto; bottom: 0; height: 6px; background: rgba(255,255,255,0.3);
	}
	&__progress-bar{
		position: absolute; top: 0; bottom: 0; left: 0; width: 0; background: var(--theme);

		.swiper-slide-active &{  animation: progress linear 5s; animation-fill-mode: both }
	}

	&__controls{
		position: absolute; display: inline-flex; bottom: $spacer*2; right: $spacer*1.5; z-index: 2;

		.swiper-button-disabled{ opacity: 0 }
	}

	&__pagination{
		position: absolute; display: inline-flex; bottom: $spacer*2; z-index: 2;
		right: auto;
		left: 50%;
		transform: translateX(-50%);
	}


	&__fraction{
		margin: 0 $spacer/2; visibility: hidden;

		&--visible{ visibility: initial }
	}
	&__prev,&__next{ transition: allow(opacity); font-size: 17px; margin-top: -1px }

	&__content{
		position: absolute; left: $spacer*1.5; width: 75%;

		@media #{$from-small}{ bottom: $spacer*3.3; left: $spacer*3 }
	}

	.gradient {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 50%;
		background: linear-gradient(to bottom, #0000 0%, #000000ab 100%);
	  }

	&__link{ position: absolute; left: 0; right: 0; top: 0; bottom: 0}

	.card-img{
		&:after{ content: ''; position: absolute; top :0; right: 0; left: 0; bottom: 0; background: linear-gradient(transparent 75%,rgba(0,0,0,0.5) 100%); }
		img{ width: 100%; height: auto }
	}
}

.swiper-pagination-bullet {
	display: flex;
	align-items: center;
	margin: 0 0 0 14px;
}

.swiper-pagination-bullet .progress {
	width: 0px;
	transition: all 0.2s ease
}

.swiper-pagination-bullet .progress-bar {
	background-color: white;
	position: absolute;
	left: 0px;
	top: 0px;
	height: 100%;
}

.swiper-pagination-bullet-active .progress-bar {
	animation: progressbaranim linear 5s;
}

.swiper-pagination-bullet-active .progress {
	display: block;
	margin: 0 0 0 6px;
	height: 3px;
	background-color: rgba(255, 255, 255, 0.15);
	width: 50px;
	position: relative;
}

@media (min-width: 600px) {
	.o-home-slider__pagination {
		bottom: 2rem;
		display: inline-flex;
		position: absolute;
		right: 2rem;
		left: auto;
		transform: none;
		z-index: 2;
	}
}

@keyframes progressbaranim {
	0%{ width: 0 }
	100% { width: 100% }
}