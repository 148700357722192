body {
    color: white;
    background: black;
    font-family: "Epilogue", sans-serif;
    font-size: 14px;
    letter-spacing: -0.4px;
    line-height: 18px;


    & > #cgBannerBTF1,
    & > #cgSidebarBTF1,
    & > #cgSidebarATF1,
    & > #cgSidebarATF1 {
      display: none;
    }
  }
  .obfuscate:hover {
    cursor: pointer;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  body::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    body {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }


  @media #{$from-small} {
    .o-header {
      margin-top: 250px;
    }
    .cultureg--full {
      height: 250px;
    }
  }

  html {
    font-size: 10px;
  }
  /* epilogue-300 - latin */
  @font-face {
    font-family: "Epilogue";
    font-display: swap;
    font-style: normal;
    font-weight: 300;
    src: local(""),
      url("../static/fonts/epilogue-v11-latin-300.woff2") format("woff2"),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../static/fonts/epilogue-v11-latin-300.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* epilogue-regular - latin */
  @font-face {
    font-family: "Epilogue";
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local(""),
      url("../static/fonts/epilogue-v11-latin-regular.woff2") format("woff2"),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../static/fonts/epilogue-v11-latin-regular.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* epilogue-600 - latin */
  @font-face {
    font-family: "Epilogue";
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local(""),
      url("../static/fonts/epilogue-v11-latin-600.woff2") format("woff2"),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../static/fonts/epilogue-v11-latin-600.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* epilogue-900 - latin */
  @font-face {
    font-family: "Epilogue";
    font-display: swap;
    font-style: normal;
    font-weight: 900;
    src: local(""),
      url("../static/fonts/epilogue-v11-latin-900.woff2") format("woff2"),
      /* Chrome 26+, Opera 23+, Firefox 39+ */
        url("../static/fonts/epilogue-v11-latin-900.woff") format("woff"); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  @font-face {
    font-family: "icons";
    src: url("../static/fonts/serieously.eot");
    src: url("../static/fonts/serieously.eot?#iefix") format("embedded-opentype"),
      url("../static/fonts/serieously.woff") format("woff"),
      url("../static/fonts/serieously.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  .card-body {
    padding: 1.5rem;
  }

  .content-container {
    @media #{$from-small} {
      padding-left: $spacer * 3;
      padding-right: $spacer * 3;
    }
  }

  .cultureg {
    text-align: center;
    max-width: 100%;

    @media #{$from-small} {
      &--full {
        padding-bottom: $spacer * 5;
        padding-top: $spacer * 3;
      }
    }

    &--sidebar {
      margin-top: $spacer * 2;
    }
  }

  .tax-category_video #cgBannerATF1,
  .single-video #cgBannerATF1 {
    display: none;
  }

  .search-results .content-container--pad {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
  }
  .single .content-container--pad {
    padding-top: $spacer;
    padding-bottom: $spacer * 2;
  }

  .page-title,
  %page-title {
    font-size: $font-xl;
    line-height: 29px;
    letter-spacing: -0.4px;
    font-weight: 600;
    & + * {
      margin-top: $spacer * 4;
    }

    &--cms {
      font-weight: 900;
      @media #{$from-small} {
        font-size: $font-xxl;
        line-height: 1.1;
      }
    }
  }

  .btn-lg {
    font-size: $font-m;
    padding: $spacer * 1 $spacer * 5;
    text-transform: uppercase;
    font-weight: 600;
  }

  .link,
  %link {
    display: inline-block;
    position: relative;

    &:before {
      content: "";
      bottom: 0;
      position: absolute;
      display: block;
      width: 100%;
      transition: allow(transform, 0.3s, $ease-in-out);
      transform: scaleX(0);
      height: 2px;
      background: $link;
    }

    .no-touch &:hover,
    &--active {
      &:before {
        transform: none;
      }
    }

    &--thin {
      &:before {
        height: 1px;
      }
    }

    &--fromleft {
      &:before {
        transform-origin: left;
      }
      .no-touch &:hover,
      &--active {
        &:before {
          transform-origin: right;
        }
      }
    }
  }

  .link-inline,
  %link-inline {
    background-image: linear-gradient($link, $link);
    background-position: 0% 100%;
    font-weight: 600;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    transition: background-size 0.3s;

    &:hover {
      background-size: 0% 1px;
      background-position: 100% 100%;
    }
  }

  .button,
  %button {
    overflow: hidden;
    position: relative;
    font-size: 1.4rem;
    padding: 1rem 5rem;
    text-transform: uppercase;
    font-weight: 600;
    line-height: 1;
    outline: none;
    -webkit-appearance: none;
    opacity: 1;

    &:before {
      content: "";
      display: block;
      position: absolute;
      z-index: 1;
      backface-visibility: hidden;
      width: 120%;
      height: 100%;
      left: -10%;
      top: 0;
      animation: out 0.6s $ease-in-out-cubic;
      transform: translate3d(-120%, 0, 0) skew(-25deg);
    }

    &:hover {
      &:before {
        transform: translate3d(0, 0, 0) scaleX(1.1) skew(25deg);
        animation: in 0.6s $ease-in-out-cubic;
      }
    }

    &--outline-red {
      color: theme-color("red");
      background-color: transparent;
      border: solid 1px theme-color("red");
      &:before {
        background: theme-color("red");
      }

      &:hover {
        color: white;
        background: transparent;
      }
    }

    &--red {
      color: white;
      background-color: theme-color("red");
      border: solid 1px theme-color("red");
      &:before {
        background: theme-color("dark");
      }
    }

    &--input {
      padding: 0;
      input {
        padding: 1rem 5rem;
        border: none;
        &:hover {
          border: none;
          background: none;
        }
      }
      span.ajax-loader {
        position: absolute;
        right: 0;
        top: 1rem;
      }
    }

    & > span,
    &--input input {
      color: inherit;
      position: relative;
      z-index: 2;
      transition: allow(color);
      backface-visibility: hidden;
      background: none;
    }
  }

  @keyframes in {
    from {
      transform: translate3d(-110%, 0, 0) skew(-25deg);
    }

    to {
      transform: translate3d(0, 0, 0) scaleX(1.1) skew(25deg);
    }
  }

  @keyframes out {
    from {
      transform: translate3d(0, 0, 0) scaleX(1.1) skew(25deg);
    }

    to {
      transform: translate3d(110%, 0, 0) skew(25deg);
    }
  }

  .wpgdprc.wpgdprc-consent-bar {
    background: rgba(0, 0, 0, 0.5) !important;
    top: 0;
    z-index: $zindex-modal;
    padding: 0;

    @media #{$from-small} {
      background: #ffffff !important;
      top: auto;
    }

    .wpgdprc-consent-bar__container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      right: 15px;
      background: #ffffff;

      @media #{$from-small} {
        top: auto;
        transform: none;
        position: static;
        width: 100%;
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .wpgdprc-consent-bar__content {
      flex-direction: column;
      text-align: center;
      padding-top: $spacer * 4;
      padding-bottom: $spacer * 3;
      font-family: Epilogue, sans-serif;
      font-size: 1.6rem;
      letter-spacing: -0.4px;
      line-height: 20px;
      border-top: solid 6px theme-color("red");

      @media #{$from-small} {
        border-top: none;
        display: grid;
        text-align: left;
        padding-bottom: $spacer * 4;
        grid-template-areas:
          "content cta"
          "content link";
        grid-gap: 6%;
        grid-template-columns: 8fr 3fr;
      }
    }

    .wpgdprc-consent-bar__column {
      & + .wpgdprc-consent-bar__column {
        margin-top: $spacer * 2;
      }

      &:nth-of-type(2) {
        order: 2;
      }

      @media #{$from-small} {
        & + .wpgdprc-consent-bar__column {
          margin-top: 0;
        }
        &:first-of-type {
          grid-area: content;
        }
        &:nth-of-type(2) {
          grid-area: link;
          text-align: center;
          align-self: flex-start;
        }
        &:nth-of-type(3) {
          grid-area: cta;
          text-align: center;
          padding-top: 5rem;
        }
      }
    }

    .wpgdprc-consent-bar__settings {
      font-size: $font-m;
      text-decoration: none;
      display: inline-block;

      &:after {
        content: "";
        display: block;
        height: 1px;
        background: theme-color("red");
      }
    }

    .wpgdprc-button.wpgdprc-consent-bar__button {
      @extend %button;
      @extend %button--red;
      padding: 11px $spacer * 2 $spacer;
      font-weight: 600;
      letter-spacing: -0.6px;
      line-height: 18px;
      background: theme-color("red") !important;
      border-color: theme-color("red") !important;
      width: 100%;
      &:hover {
        border-color: theme-color("red") !important;
        background: theme-color("red") !important;
      }
    }

    .wpgdprc-consent-bar__notice {
      @media #{$from-small} {
        max-width: none;
      }
      p {
        font-family: Epilogue, sans-serif;
      }
      p > strong {
        font-family: Epilogue, sans-serif;
        font-size: 28px;
        font-weight: 900;
        letter-spacing: -0.4px;
        line-height: 29px;
        display: inline-block;
        @include emoji("cookie");
        margin-bottom: $spacer;

        &:before {
          display: block;
          margin-bottom: $spacer;
          @media #{$from-small} {
            display: inline-block;
            margin-right: $spacer;
            margin-bottom: 0;
            font-size: $font-ml;
          }
        }
      }
    }
  }

  .notfound {
    padding-bottom: $spacer * 4;
    overflow: hidden;
    margin-left: -15px;
    margin-right: -15px;
    @media #{$from-small} {
      margin: 0;
    }

    &__hero {
      position: relative;
      background-size: 100%;
      background-image: linear-gradient(
        90deg,
        #e5e5e5 14.29%,
        theme-color("yellow") 14.29%,
        theme-color("yellow") 28.57%,
        theme-color("blue") 28.57%,
        theme-color("blue") 42.86%,
        theme-color("green") 42.86%,
        theme-color("green") 57.14%,
        theme-color("purple") 57.14%,
        theme-color("purple") 71.43%,
        theme-color("red") 71.43%,
        theme-color("red") 85.71%,
        theme-color("darkblue") 85.71%,
        theme-color("darkblue") 100%
      );

      &:before {
        content: "";
        padding-top: 78.6%;
        display: block;
        @media #{$from-small} {
          padding-top: 56.7%;
        }
      }
      &:after {
        content: "";
        display: block;
        height: 1.8rem;
        background-size: 100%;
        background-image: linear-gradient(
          90deg,
          theme-color("darkblue") 14.29%,
          theme-color("dark") 14.29%,
          theme-color("dark") 28.57%,
          theme-color("purple") 28.57%,
          theme-color("purple") 42.86%,
          theme-color("dark") 42.86%,
          theme-color("dark") 57.14%,
          theme-color("blue") 57.14%,
          theme-color("blue") 71.43%,
          theme-color("dark") 71.43%,
          theme-color("dark") 85.71%,
          theme-color("#D8D8D8") 85.71%,
          #d8d8d8 100%
        );

        @media #{$from-small} {
          height: 3.2rem;
        }
      }

      & + * {
        margin-top: $spacer * 4;

        @media #{$from-small} {
          margin-top: $spacer * 5;
        }
      }
    }

    &__content {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      line-height: 1;
      font-weight: 900;
    }

    &__title {
      position: relative;
      z-index: 1;
      font-size: 4.2rem;
      color: white;
      background: theme-color("dark");
      padding: $spacer 8px;
      margin-top: -1.5rem;
    }

    &__subtitle {
      transform: rotate(-4deg);
      font-size: $font-xl;
      padding: $spacer 8px;
      background: white;
      color: theme-color("dark");
      text-align: center;
    }

    &__slider {
      &-container {
        margin-left: 15px;
        margin-right: 15px;
        @media #{$from-small} {
          margin-left: 0;
          margin-right: 0;
        }
      }
      &.swiper-container {
        overflow: visible;
      }
      &-title {
        text-align: center;
        margin-bottom: $spacer * 2;

        span:after {
          margin-left: 0.5rem;
        }
      }

      .a-post--single .a-post__title {
        -webkit-line-clamp: 3;
      }
    }
  }

  .about {
    padding: 4rem 0 5rem;

    &__content {
      @extend %wysiwyg;
      & + * {
        margin-top: $spacer * 3;
      }
    }

    &__grid {
      display: grid;
      grid-gap: 6rem;
      @media #{$from-small} {
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 7rem 3rem;
      }
    }
  }

  .post-type-page {
    .article-body {
      padding: 2rem 0 5rem;
      @extend %wysiwyg;
      @media #{$from-small} {
        padding: 4rem 0;
      }
      h1 {
        @extend %page-title;
        strong {
          font-weight: 600;
          word-break: break-word;
        }
        @media #{$from-small} {
          font-weight: 900;
          strong {
            font-weight: inherit;
          }
        }
      }
    }
  }

  .wysiwyg,
  %wysiwyg {
    figure,
    img {
      max-width: 100%;
      height: auto;
      border: none;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    iframe {
      max-width: 100%;
    }

    figcaption {
      font-size: $font-s;
      opacity: 0.5;
      text-align: center;
      margin-top: $spacer;
      @media #{$from-small} {
        margin-top: $spacer * 2;
      }
    }
    h2 {
      font-size: 2.4rem;
      font-weight: 600;
      letter-spacing: -0.46px;
      line-height: 1.2;
      @media #{$from-small} {
        font-size: 2.4rem;
      }
    }
    h3 {
      font-size: $font-mm;
      font-weight: 600;
      letter-spacing: -0.46px;
      line-height: 24px;
      strong {
        font-weight: 600;
      }
    }
    & > * + * {
      margin-top: $spacer * 2;
    }
    & > * + h2,
    & > figure + * {
      margin-top: $spacer * 3;
    }
  }

  #qc-cmp2-container {
    #qc-cmp2-ui {
      margin-left: 1rem;
      margin-right: 1rem;
      padding-top: $spacer * 3;
      border-top: solid 5px theme-color("red");
      @media #{$from-small} {
        margin-left: 0;
        margin-right: 0;
        border: none;
      }
      .qc-cmp2-publisher-logo-container h2 {
        @include emoji("cookie");
        display: flex;
        &:before {
          margin-right: 5px;
        }
      }
      .qc-cmp2-footer .qc-cmp2-buttons-desktop button[mode="secondary"] {
        height: auto;
      }

      * {
        font-family: "Epilogue", sans-serif;
      }
    }
  }
  /* Pub sur mobile (placée après la première p du contenu) */
  #cgFirstInContent {
    min-height: 250px;
    max-height: 640px;
  }
  .cultureg .cultureg--full {
    min-height: 90px;
  }
  @media #{$from-small} {
    #cgFirstInContent {
      display: none;
    }
  }
  .logo-menu {
    height: 23px;
    flex-shrink: 0;
    margin-left: 1rem;
  }

  #nav-collapse {
    .logo-menu {
      height: 39px;
      margin-left: 0;
      margin-top: 1rem;
      flex-shrink: 0;

      svg {
        height: 39px;
      }
    }
  }

  .wnext {
    display: flex;
    margin-left: 1rem;
    margin-right: 1rem;
    border-style: solid;
    position: relative;
    padding-left: 0.5rem;
    background-color: #fff;
    padding-right: 0.5rem;
    color: #000;
    align-items: center;
    border-width: 1px;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      90deg,
      #efcf47,
      #efcf47 17%,
      #6be3de 0,
      #6be3de 34%,
      #6ecf44 0,
      #6ecf44 51%,
      #d334e1 0,
      #d334e1 67%,
      #af3635 0,
      #af3635 84%,
      #1f3ff3 0,
      #1f3ff3
    );
  }
  .wnext-anim {
    display: none;
  }
  @media screen and (min-width: 768px) {
    .wnext {
      overflow: hidden;
    }

    .wnext-anim {
      display: block;
      overflow: hidden;
      position: absolute;
      top: 0;
      right: -100%;
      bottom: -100%;
      left: -100%;
      background-color: currentColor;
      transform: skew(12deg);
      width: 66.666667%;
      height: 100%;
      opacity: 1;
    }
  }

  .wnext a {
    height: 100%;
  }
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .fill-current {
    fill: currentColor;
  }
  .wnext:hover {
    background-color: #000;
    color: #fff;
  }
  .wnext:hover .animate-shine {
    animation: shine 0.55s ease-in-out;
  }
  @keyframes shine {
    to {
      left: 125%;
    }
  }

  .b-sidebar-body {
    .wnext {
      display: inline-flex;
      height: 3rem;
      margin: 0.5rem auto;
    }
  }
  .viously-has-ui {
    margin-bottom: 2rem;
  }
  #vsly-top-of-page {
    background: #ddd;
    padding-top: 56.25%;
    font-size: 0;
    position: relative;
    overflow: hidden;
    width: 100%;
    @media (min-width: 480px) {
      display: none;
    }
  }
  @media (max-width: 480px) {
    .vsly-player {
      display: none;
    }
  }
