.m-social{

	&__item{
		font-size: 18px; transition: allow(color);

		& + &{ margin-left: $spacer*4 }

		.no-touch &:hover{ color: theme-color('red') }
	}

}
