.o-social{
	background: theme-color('red'); color: white; text-align: center; padding: $spacer*3 0 $spacer*3;

	&__title{
		font-size: $font-mm; font-weight: 600;

	}

	.no-touch & .m-social__item:hover{ color: theme-color('dark')}

}
