.o-home-posts{

	.nav-tabs{
		border: none; justify-content: space-between;

		@media #{$from-small}{
			justify-content: flex-start;

			.nav-item + .nav-item{ margin-left: $spacer*2 }
		}

	}

	.nav-link{
		text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white; color: black;
		font-size: 2.4rem; font-weight: 900; background: none; border: none; letter-spacing: -0.34px; padding: 0;
		transition: allow(text-shadow color); height: 70px; padding-top: $spacer*3;

		[data-icon]:before{ text-shadow: none; color: theme-color("red") }
		[data-emoji]:before{ text-shadow: none }

		&.active{ color: white;  background: none; border: none; text-shadow: none }

		@media #{$to-phone-s}{
			font-size: 2rem;
		}
	}
}