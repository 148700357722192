.o-quiz{

	&__result{
		margin-top: $spacer*5; border: solid 1px #fff; padding: $spacer*1.5 $spacer*1.5 $spacer*3;

		&-title{ font-weight: 600 }
		&-image img{ width: 100%; height: auto }
		&-subtitle + *, &-image +*{ margin-top: $spacer*2 }
		&-description + &-image{ margin-top: $spacer*2 }

		&-description > a{ @extend %link-inline }
	}

	&__share{
		margin-top: $spacer*5; text-align: center;

		&-title--success{ color: theme-color('success') }
		&-icons{
			display: flex; justify-content: center; margin-top: $spacer*2;

			& > * + *{ margin-left: $spacer*4 }
		}
	}

	&__restart{
		.button{ display: inline-block; padding: 1.5rem 5rem; }
	}

}