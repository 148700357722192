share{ display: none }

.a-share{

	position: relative; display: inline-block;

	& + *{ margin-top: $spacer*1.5 }

	&__action{
		cursor: pointer; font-size: $font-s; display: flex; align-items: center; padding-top: 5px;

		[data-icon]{ display: inline-block; margin-top: -1px; margin-right: 5px }
	}

	&__underlined{ text-decoration: underline }

	&__copy-confirm{
		position: absolute; left: 100%; margin-left: 15px; background: white; padding: 2px 3px 1px; font-size: $font-s; top: 4px;
		white-space: nowrap; border-radius: 2px; color: theme-color('dark');
		&:after {
			right: 100%; top: 50%; border: solid transparent; content: ""; height: 0; width: 0; position: absolute; pointer-events: none;
			border-color: transparent; border-right-color: white; border-width: 4px; margin-top: -4px;
		}
	}
}