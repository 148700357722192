.o-article {
  font-size: 1.8rem;
  line-height: 1.4;

  @media #{$from-small} {
    font-size: $font-mm;
    margin-right: 20px;
  }

  &__title {
    padding-top: 20px;
  }

  &__title + * {
    margin-top: $spacer/2;
  }

  &__thumbnail {
    img {
      width: 100%;
      height: auto;
    }
    & + * {
      margin-top: $spacer * 3;
    }
    @media #{$from-small} {

      & + * {
        margin-top: $spacer * 4;
      }
    }
  }

  &__published-by {
    font-size: $font-s;
  }

  &__bullets {
    & > * + * {
      margin-left: $spacer/3;
      &:before {
        content: "\25CF";
        color: var(--theme);
        margin-right: $spacer/3;
        font-size: 0.9rem;
      }
    }
  }

  p {
    opacity: 0.9;
  }

  .o-article__body {
    @extend %wysiwyg;

    p {
      line-height: 1.4;
    }

    strong {
      font-size: 1.8rem;
      font-weight: 600;
      letter-spacing: -0.46px;
      line-height: 1.4;
      @media #{$from-small} {
        font-size: $font-ml;
      }
    }

    .gallery {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: $spacer * 2;

      figure.gallery-item {
        margin-left: 0;
        margin-right: 0;
      }

      @media #{$from-small} {
        grid-template-columns: 1fr 1fr;
      }
    }

    p > a,
    li > a {
      @extend %link-inline;
    }

    .quizz {
      display: none !important;
    }
  }

  .article-content {
    padding-bottom: $spacer * 3.5;
  }
}
.notavideoplayer-quiz {
  display: none;
  @media #{$from-small} {
    display: block;
  }
}

.viously-desktop {
  @media #{$from-small} {
    background: #000;
    padding-top: 56.25%;
    font-size: 0;
    position: relative;
    overflow: hidden;
    width: 100%;
  }
}

.rank-math-breadcrumb {
  font-size: $font-s;
  a {
    text-transform: capitalize;
  }
}

.newsletter {
  .gform_body input {
    border-radius: 0.3125rem;
    background: #fff;
  }
  .gform_footer p {
    width: 100%;
    vertical-align: middle;
    font-size: 1rem;
    font-style: italic;
    display: inline-flex;
    margin: auto 0;
}

  .gform_footer input {
    background: #000;
    border-radius: 0.3125rem;
    color: #fff;
    font-size: 1.2rem;
    height: 2.8rem;
    margin: auto;
    padding: 0;
    text-transform: capitalize;
    width: 105px;
    margin-bottom: auto !important;
    margin-top: auto;
  }
}
