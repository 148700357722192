.m-question-timer{

	& + &{ margin-top: $spacer*3 }

	&__title{ font-weight: 600 }

	&__timer{ font-size: 4.2rem; font-weight: bold; text-align: center; }
	&__score{
		b{ font-size: 3.2rem }
		text-align: center; font-size: 2.2rem
	}
	&__inner{
		margin-top: 1rem; position: relative;

		picture, img{ width: 100% }
	}
	&__overlay, &__message{
		position: absolute; left: 0; width: 100%; height: 100%; top: 0; background: rgba(0,0,0,0.2);
		align-items: center; justify-content: center; display: flex; flex-direction: column;
	}
	&__freetext{
		background: #121212; border: 1px solid #fff; width: 70%; margin-left: auto; margin-right: auto; position: relative; transition: allow(border);
		&:after{ position: absolute; right: 15px; top: $spacer }
		input{ border: none; padding: $spacer; background: transparent; color: white; width: 100% }
		&--correct{
			@include emoji('thumbsup','after');
		}
		&--wrong{
			@include emoji('thumbsdown','after');
		}
	}
	&__information{
		background: #000; text-align: center; padding: 3rem 1rem; font-size: 1.8rem; border: 1px solid #000; transition: allow(border, $speed-fast); position: relative; line-height: 1;
		&--correct{ border-color: #13E8DA }
		&--wrong{ border-color: #BE272F }
		b{ font-size: 12px }
	}
	&__buttons{
		position: absolute; top: 15px; left: 15px; z-index: 2;
	}
	&__button{
		transition: allow(color background); color: #fff; cursor: pointer; border: 1px solid #fff; display: inline-block; padding: 3px 8px; font-size: 11px;
		&:hover{ background: #fff; color: #000}

		&--replay:before{ margin-right: 4px; font-size: $font-m }
	}
	&__message{ background: #000 }
	&__attempt{
		margin: 2px; font-size: 12px;
		&+&{ &:before{ content: '-'; margin-right: 4px } }
	}
	&__sharer{ margin-top: 2rem }
	&__missing{ display: block }
	&__share{
		padding: 8px 20px; border-radius: 4px; color: #fff; margin: 5px; cursor: pointer;
		&--facebook{ background: $color-facebook}
		&--twitter{ background: $color-twitter }
	}
}