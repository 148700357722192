.o-list-videos{

	&__scrollable{
		margin-top: $spacer*3;
		@media #{$from-small}{
			margin-top: 0; position: absolute; top: 0; bottom: 0; overflow-y: auto; padding-right: 5px; padding-left: 15px;
			&::-webkit-scrollbar { width:  5px; height: 5px }
			&::-webkit-scrollbar-thumb { background: #888; border-radius: 0 }
			&::-webkit-scrollbar-track { background: theme-color('dark'); border-radius:0 }
		}
	}
}