$container-max-widths: (
	xxl: 1000px
);

$theme-colors: (
	"theme":      #868686,
	"blue":       #13E8DA,
	"purple":     #E715E7,
	"red":        #BE272F,
	"yellow":     #F5CF06,
	"green":      #3FD21C,
	"dark":       #121212,
	"darkblue":   #0E43FF,
	"black": #000000,
	"orange":     #FF8C00,
	"success": #13E8DA,
	"danger": #BE272F
);

$link: #BE292E;

$grid-gutter-width: 20px;

$grid-breakpoints: (
	// Extra small screen / phone
	xs: 0,
	// Small screen / phone
	sm: 576px,
	// Medium screen / tablet
	md: 768px,
	// Large screen / desktop
	lg: 1023px,
	// Extra large screen / wide desktop
	xl: 1440px
);

$font-s: 1.1rem;
$font-m: 1.4rem;
$font-mm: 1.6rem;
$font-ml: 1.8rem;
$font-l: 2.2rem;
$font-xl: 2.8rem;
$font-xxl: 4.2rem;

$data-attributes : animation, icon, transition, emoji;


$emojis:
fire "\01F525",
grin "\01F600",
cookie "\01F36A",
eyes  "\01F440",
waving "\01F44B",
thumbsup "\01F44D",
thumbsdown "\01F44E",
claping "\01F44F",
belier	"\02648",
taureau	"\02649",
gemeaux	"\0264A",
cancer	"\0264B",
lion	"\0264C",
vierge	"\0264D",
balance	"\0264E",
scorpion	"\0264F",
sagittaire	"\02650",
capricorne	"\02651",
verseau	"\02652",
poisson	"\02653";

$icons:
instagram "a",
youtube "b",
next "c",
previous "d",
search "e",
tiktok "f",
trending "g",
burger "h",
facebook "i",
twitter "j",
close "k",
share "l",
mail "m",
play "n",
refresh "o";