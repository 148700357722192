.m-question{
	$width: 2.2rem;
	$c: &;

	& + &{ margin-top: $spacer*5 }

	img{ width: 100%; height: auto }

	&__title{
		font-size: $font-ml; font-weight: 600; letter-spacing: -0.45px;

		& + *{ margin-top: $spacer }
	}

	&__title,&__answer, &__result{
		a{ @extend %link-inline }
	}

	* + &__answers{ margin-top: $spacer*2 }

	.inactive{
		pointer-events: none;
		& > *{ opacity: 0.7 }
		.m-question__answer, .m-question__truefalse, .m-question__freetext, .m-question__images{
			&--correct, &--wrong{ opacity: 1 }
		}
	}

	&__answers{
		&--true-false{
			text-align: center;
		}

		&--images{
			display: grid; grid-template-columns: 1fr 1fr; grid-gap: $spacer*2;

			@media #{$from-small}{
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
			#{$c}__answer{
				text-align: center; display: flex; flex-direction: column; cursor: pointer;
				picture, img{
					width: 100%;
				}
				picture{
					margin-bottom: $spacer*1.5;
				}
				& + #{$c}__answer{ margin-top: 0 }
				.form-check{ flex: 1 }
			}
		}

		&--astro{
			display: grid; grid-template-columns: 1fr 1fr; grid-gap: $spacer*2;

			@media #{$from-small}{
				grid-template-columns: 1fr 1fr 1fr;
			}

			#{$c}__answer{
				text-align: center; display: flex; flex-direction: column; cursor: pointer;
				picture, img{
					width: 100%;

					& + *{ margin-top: $spacer*1.5 }

				}

				picture{ border: solid 2px rgba(255,255,255,0.7) }

				& + #{$c}__answer{ margin-top: 0 }
				.form-check{ flex: 1 }
				#{$c}__answer-label{ margin-left: 2px }

				&--selected {
					picture{ border-color: theme-color('success') }
					#{$c}__answer-label{ color: theme-color('success') }
				}
			}


		}

		&--gallery{
			display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: $spacer/2;

			picture, img{
				width: 100%;
			}

			@media #{$from-small}{
				grid-template-columns: 1fr 1fr 1fr 1fr;
			}
		}

		&--blocks{
			& > * + *{ margin-top: $spacer*2 }

			#{$c}__answer--selected{
				color: theme-color('success');
				.form-check-label {
					.checkbox{
						color: theme-color('success');
						border-color: theme-color('success');
	
						&:before {
							content: "\2713";
						}
					}
				}
			}
		}


	}

	&__images{
		border: solid 2px rgba(255,255,255,0.7); cursor: pointer;

		&--correct, &--right{ border-color: theme-color('success') }
		&--correct{ opacity: 1 }
		&--wrong{ border-color: theme-color('danger'); opacity: 1 }
	}

	&__freetext{
		width: 63%; margin-left: auto; margin-right: auto; position: relative; border-bottom: solid 1px rgba(255,255,225,0.7);
		&:after{ position: absolute; right: 0; top: $spacer }
		input[type="text"]{ border: none;  padding: $spacer; background: transparent; color: white; width: 100% }
		input[type="submit"]{ position: absolute; pointer-events: none }

		&--correct{
			@include emoji('thumbsup','after'); opacity: 1;
			input{ border-bottom-color: theme-color('success') }
		}

		&--wrong{
			@include emoji('thumbsdown','after'); opacity: 1;
			input{ border-bottom-color: theme-color('danger') }
		}
	}

	&__truefalse{
		width: 14rem; height: 14rem; display: inline-flex; align-items: center; justify-content: center; border: solid 1px rgba(255,255,225,0.7); cursor: pointer;
		border-radius: 2px; font-weight: 600;
		& + &{ margin-left: $spacer*2 }
		&:before{ margin-right: $spacer/2 }

		&--correct, &--right{
			border-color: theme-color('success')
		}
		&--correct,&--wrong{ opacity: 1 }
		&--wrong{
			border-color: theme-color('danger')
		}
	}

	.form-check{
		padding-left: 0; line-height: 2.2rem; opacity: 0.9; cursor: pointer; text-align: left;

		&-input{
			left: 0; right: 0; top: 0; bottom: 0; width: 100%; height: 100%; z-index: 1; cursor: pointer;
		}

		&-label{ position:relative; z-index: 2; cursor: pointer }

		&-label {
			display: flex;
			align-items: center;

			.checkbox{ 
				padding-right: 1px;
				padding-top: 1px; 
				border: solid 1px rgba(255,255,225,0.7);
				width: $width;
				height: $width;
				text-align: center;
				left: 0; 
				top:-1px;
				margin-right: 6px;
				position: relative;

				&:before {
					content: '';
					height: 19px;
					position: absolute; left: 50%; top:50%; transform: translate(-50%, -50%)
				}

				.ios &{ padding-top: 0 }
			}
		}

		&-input[type="radio"]+.form-check-label {
			.checkbox{ border-radius: 50% }
		}

		&-input:checked + .form-check-label.checkbox{ &:before { content: "\2713";}  }
	}

	&__answer{
		& + &{ margin-top: $spacer*2 }

		&-label{ text-align: left }

		picture{ border: solid 1px rgba(255,255,255,0.7)}

		&--correct{
			picture{ border-color: theme-color('success') }
			.form-check-input + .form-check-label, #{$c}__answer-label{
				color: theme-color('success');

				.checkbox{
					color: theme-color('success');
					border-color: theme-color('success');

					&:before {
						content: "\2713";
					}
				}
			}
		}

		&--right{
			picture{ border-color: theme-color('success') }
			.form-check-input + .form-check-label, #{$c}__answer-label{
				color: theme-color('success');

				.checkbox{
					color: theme-color('success');
					border-color: theme-color('success');

					&:before {
						content: "\2713";
					}
				}
			}
		}
		&--correct,&--wrong{ opacity: 1 }
		&--wrong{
			picture{ border-color: theme-color('danger') }
			color: theme-color('danger');
			.form-check-input:checked + .form-check-label, #{$c}__answer-label{
				color: theme-color('danger');
			}
			.form-check-input:checked + .form-check-label{
				color: theme-color('danger');
				.chrome.android &{ font-size: 2rem; padding-top: 1px }
					.android &, .ios &{ content: '\2715'; }

				.checkbox{
					border-color: theme-color('danger'); 

					&:before {
						content: '\2716'; padding-top: 0; color: theme-color('danger');
					}
					
				}
			}
		}
	}

	&__result{
		border: solid 1px transparent; padding: $spacer*1.5; font-size: $font-m; margin-top: $spacer*2;

		&-header{
			font-weight: 600; margin-bottom: 0.5rem;
			&:before{ margin-right: $spacer }
		}

		&--correct{
			border-color: theme-color('success'); opacity: 1;
			#{$c}__result-header{ color: theme-color('success') }
		}

		&--wrong{
			border-color: theme-color('danger'); opacity: 1;
			#{$c}__result-header{ color: theme-color('danger') }
		}

		* + picture{ margin-top: $spacer }
	}

	&__image{ position: relative }

	&__credit{ font-size: $font-s; text-shadow: 0 1px 1px rgba(0,0,0,0.3); font-weight: normal; position: absolute; bottom: 5px; right: 10px  }
}