.page-template-contact {
  .article-body h1 {
    text-align: center;
    font-weight: bold;
    font-size: 4.2rem;
  }

  .gform-body {
    .gfield_description,
    .gform-field-label {
      color: white;
    }
    input::-webkit-input-placeholder {
      opacity: 0.7;
    }
    input {
      background-color: #121212 !important;
      border: none !important;
      border-bottom: 1px solid #fff !important;
      color: white !important;
    }
  }

  .gform_footer input {
    background-color: #be272f !important;
    font-weight: bold;
  }
}
