$color-dribbble   : #ea4c89;
$color-facebook   : #3b5998;
$color-flickr     : #ff0084;
$color-googleplus : #dd4b39;
$color-instagram  : #d35400;
$color-linkedin   : #007bb6;
$color-pinterest  : #cb2027;
$color-tumblr     : #32506d;
$color-twitter    : #00aced;
$color-vimeo      : #1ab7ea;
$color-vine       : #00bf8f;
$color-youtube    : #bb0000;