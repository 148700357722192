@mixin reset {

h1, h2, h3, h4, h5, h6{ font-weight: normal; font-size: inherit }

article, aside, details, figcaption, figure, footer, header, menu, nav, section, main, picture {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

template {
	display: none;
}

a, input, button, [role="button"] {
	&:active {
		-webkit-tap-highlight-color: rgba(0,0,0,0);
	}
	&:focus { outline:none; }
	&::-moz-focus-inner { border:0; }
}

body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

a {
	color: inherit;
	text-decoration: none;
	-webkit-tap-highlight-color: transparent;
	&[href]{ cursor: pointer }
}


input, textarea {
	outline: none; border: none; padding: 0;
}

input, button {
	-webkit-appearance: none
}

textarea {
	resize: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
	display: none;
}

*, *:before, *:after{ box-sizing: border-box }

img{ max-width: 100%; height: auto; display: block }
}

$reset-layout : true!default;

@if( $reset-layout ){
	@include reset;
}
