.m-question-astro{
	$c: &;

	&.inactive{ pointer-events: none }

	&__grid {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: $spacer*2;

		@media #{$from-small}{
			grid-template-columns: 1fr 1fr 1fr;
		}

		* + &{ margin-top: $spacer*2 }

		.m-astro{
			margin-top: 0; padding-top: 100%; border: solid 1px white; position: relative; cursor: pointer;
			.m-question__answer{
				position: absolute; top: 0; left: 0; right: 0; bottom: 0; display: flex;  flex-direction: column;
				align-items: center; justify-content: center;
			}
			.m-question__label{
				font-size: $font-mm; margin-top: 3px; font-weight: 600;
				&::first-letter{ text-transform: uppercase }
			}

			&--selected{ border-color: theme-color('success') }
		}
	}

	#{$c}__answer{
		text-align: center; display: flex; flex-direction: column; cursor: pointer;
		picture, img{
			& + *{ margin-top: $spacer*1.5 }
		}

		picture{ border: solid 2px rgba(255,255,255,0.7) }

		& + #{$c}__answer{ margin-top: 0 }
		.form-check{ flex: 1 }
		#{$c}__answer-label{ margin-left: 2px }

		&--selected {
			opacity: 1;
			picture{ border-color: theme-color('success') }
			#{$c}__answer-label{ color: theme-color('success') }
			.form-check-label{
				color: theme-color('success');
				&.checkbox{ content: "\2713"; border-color: theme-color('success') }
			}
		}
	}
}