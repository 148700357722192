//include icon
$icons: false !default;

@mixin icon($name, $position:'before') {

  @if($position == 'before'){
    &:before{ @extend %icon, %icon-#{$name} }
  }
  @else{
    &:after{ @extend %icon, %icon-#{$name} }
  }
}

@if($icons){

  %icon{
    font-family: icons, sans-serif; text-transform: none; line-height: 1;
    font-style: normal; font-weight: normal; font-variant: normal; vertical-align: middle;
    -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;
  }

  @each $icon in $icons {

    $id      : nth($icon, 1);
    $content : nth($icon, 2);

    %icon-#{$id}{ content: $content }
  }
}


@mixin data-icon {

  [data-icon]:before, [data-icon_after]:after,
  [icon]:before, [icon-after]:after{ @extend %icon }

  @each $icon in $icons {

    $id      : nth($icon, 1);
    $content : nth($icon, 2);

    [data-icon="#{$id}"]:before, [data-icon_after="#{$id}"]:after,
    [icon="#{$id}"]:before, [icon-after="#{$id}"]:after{ @extend %icon-#{$id} }
  }
}

@if (variable-exists(data-attributes) and index($data-attributes, 'icon') and $icons) {
  @include data-icon;
}

