.o-sibling-posts{

	.a-post{
		color: theme-color('dark');
	}

	&__link{
		color: theme-color('red');  line-height: 1; display: flex; align-items: center;
		&:hover{ color: theme-color('red') }

		@media #{$from-small}{
			font-size: 1.6rem; padding: 0.5rem;

			& + *{ margin-top: $spacer*3 }
		}

		&:before,&:after{
			font-size: 9px;  line-height: $font-m;
			@media #{$from-small}{ margin-top: -2px; font-size: $font-ml }
		}

	}

	&__hover{ padding: $spacer 0; @extend %link; @extend %link--thin }


	[data-icon]:before{
		margin-right: 5px;
		@media #{$from-small}{ margin-right: $spacer }
	}
	[data-icon_after]:after{
		margin-left: 5px;
		@media #{$from-small}{ margin-left: $spacer }
	}
}