.o-header{
	$c: &;

	&__items{ width: 100%; position: relative; z-index: $zindex-fixed+10; display: flex; align-items: center }

	&__brand{
		img{
			max-width: 125px;
			@media #{$from-small} { max-width: 197px }
		}
	}

	.navbar-toggler{
		color: white; border: none; padding: 0; font-size: 4rem;

		&.not-collapsed span:before{ font-size: 2.2rem }
	}

	&__search{

		input{
			background: black; border: none; font-size: $font-ml; color: white; border-bottom: solid 1px rgba(white,0.5);
			letter-spacing: -0.94px; font-family: Epilogue,sans-serif; transition: allow(width); font-weight: 300;

			&::placeholder{ color: white; opacity: 0.5 }
		}
		.form-control:focus, input:focus{ color: white; background: black; border: none; border-bottom: solid 1px rgba(white,0.5); box-shadow: none }

		[data-icon]{ margin-top: -3px; font-size: 20px; display: inline-flex; align-items: center;  border-bottom: solid 1px rgba(white,0.5); margin-right: -2px; padding-right: 2px }

		.btn{ margin-left: 0.5rem; border: solid 1px theme-color("red"); white-space: nowrap; font-size: $font-m; text-transform: uppercase; padding: 2px 1rem 0; }

		&--desktop{
			.btn-secondary{ background: black; border: none }
			#{$c}__search-button{ @extend %button; @extend %button--outline-red; color: white; flex-shrink: 0 }
			.form-control:focus, input:focus{ border: none }
				input{
				font-size: $font-m; letter-spacing: -0.4px; font-weight: 400; padding-left: 0; border: none;
				::placeholder{ font-size: $font-m }

			}
			[data-icon]{ margin-right: 0; margin-left: -1px; border: none }
		}
	}

	.menu-item{
		a{
			padding: 0.7rem $spacer/2 $spacer/2; white-space: nowrap; display: inline-block; font-weight: 600; line-height: 1; letter-spacing: -0.4px;
			border: solid 1px var(--theme); background: black; transition: allow(background);
			text-shadow: 0 1px 1px rgba(0,0,0,0.2);

			&:hover{ text-decoration: none; color: white }

			.no-touch &:hover{ opacity: 0.8 }
		}
		.home &, .search &,  &.current-menu-item.primary, &.current-menu-parent.primary, &.current-post-ancestor.primary, &.current-page-ancestor.primary, .error404 &,.page &, .author &{
			a{ background: var(--theme) }
		}
	}

	&__nav-inline{
		margin-top: $spacer*0.5; margin-right: -15px; overflow: hidden; max-width: none; width: auto;



		& > *{
			overflow-y: auto;
			&::-webkit-scrollbar { opacity: 0; height: 0 }
			&::-webkit-scrollbar-thumb {  opacity: 0 }
			&::-webkit-scrollbar-track {  opacity: 0 }
		}

		ul{ display: flex; text-align: center }

		.menu-item{
			a{ padding-top: 4px; padding-bottom: 1px }
			& + .menu-item{ margin-left: 0.75rem }

			.no-touch & a{
				overflow: hidden; display: block; position: relative; background: transparent;

				&:before{
					content: ''; display: block; position: absolute; z-index: 1; backface-visibility: hidden; width: 120%; height: 100%; left: -10%; top: 0; animation: out 0.6s $ease-in-out-cubic; transform: translate3d(-120%,0,0) skew(-25deg); background: var(--theme);
				}

				&:hover{
					opacity: 1;
					&:before{ transform: translate3d(0,0,0)  scaleX(1.1)  skew(25deg); animation: in 0.6s $ease-in-out-cubic }
				}

				& > span{ color: inherit; position: relative; z-index: 2; transition: allow(color); backface-visibility: hidden; background: none }
			}

			.home &, .search &,  &.current-menu-item.primary, &.current-menu-parent.primary, &.current-post-ancestor.primary, &.current-page-ancestor.primary, .error404 &,.page &, .author &{
				a{
					background: var(--theme);
					&:before{ background-color: theme-color('dark') }
				}
			}
		}

		@media #{$from-small}{
			width: 100%;
			ul{ justify-content: center }
		}

	}


	&__nav{
		.admin-bar &{ top: 46px }

		.b-sidebar{
			padding: 50px $spacer*1.5 $spacer*1.5;
			.admin-bar &{ top: 46px; height: calc(100vh - 46px) }
		}

		.b-sidebar-body{ display: flex; flex-direction: column }

		.menu-item{
			font-size: $font-l;

			a{ padding: $spacer $spacer $spacer/2; background: var(--theme) }
			& + .menu-item{ margin-top: $spacer }
		}

		.nav-main{ margin-top: $spacer*3; flex: 1; overflow: hidden; overflow-y: auto; }
	}

	.b-sidebar-body{ padding-top: $spacer*2 }

	.m-social{ margin-top: $spacer*2; text-align: center; margin-bottom: $spacer*2;
		&__item{ font-size: 1.9rem }
	}
}
