.snap-align-start {
    scroll-snap-align: start;
}

.scrolling-touch {
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
}

.snap-type-mandatory {
scroll-snap-type: x mandatory;
}

.scrollbar-hidden {
-ms-overflow-style: none;
scrollbar-width: none;
}