.m-user{
	$c: &;

	&--about{
		&:nth-of-type(7n+1){
			#{$c}__name{ color: theme-color('yellow') }
		}
		&:nth-of-type(7n+2){
			#{$c}__name{ color: theme-color('blue') }
		}
		&:nth-of-type(7n+3){
			#{$c}__name{ color: theme-color('green') }
		}
		&:nth-of-type(7n+4){
			#{$c}__name{ color: theme-color('purple') }
		}
		&:nth-of-type(7n+5){
			#{$c}__name{ color: theme-color('red') }
		}
		&:nth-of-type(7n+6){
			#{$c}__name{ color: theme-color('darkblue') }
		}
		&:nth-of-type(7n+7){
			#{$c}__name{ color: theme-color('orange') }
		}

		#{$c}__social-contact{
			& > *{
				display: block;
				& + *{ margin-left: 0; margin-top: $spacer }
			}
		}
	}


	&--article{
		margin-top: $spacer*4.5; margin-left: 12.5%; margin-right: 12.5%;
		@media #{$from-small}{ text-align: left; display: flex }
	}

	&__name{
		font-size: $font-mm; font-weight: 600;
		@media #{$from-small}{
			#{$c}--about &{ font-size: $font-ml  }
		}
	}

	&__job{
		#{$c}--about &{
			margin-top: $spacer;
			@media #{$from-small}{ font-size: $font-ml }
		}
		#{$c}--article &{ font-size: $font-m }
	}
	&__description{
		margin-top: $spacer*2; font-size: $font-s;

		@media #{$from-small}{ margin-top: $spacer }

		#{$c}--about &{
			margin-top: $spacer*1.5;
			@media #{$from-small}{ margin-top: $spacer*2; font-size: $font-m;  opacity: 0.9  }
		}

	}

	&__social{
		font-size: $font-s; margin-top: 1.5rem; line-height: 1;

		[data-icon]:before{ margin-right: 5px }

		& > * + *{
			margin-left: 1.5rem;
			@media #{$from-small}{ margin-left: $spacer*3.5 }
		}

		span{ text-decoration: underline }
	}

	@media #{$from-small}{
		&--about &__infos{ margin-top: 9rem }
	}

	&__avatar{
		border-radius: 50%; width: 7rem; height: 7rem; overflow: hidden; margin-left: auto; margin-right: auto;

		@media #{$from-small}{
			flex-shrink: 0;
			#{$c}--article &{
				& + *{ margin-top: 0; margin-left: $spacer*2 }
			}
		}
	}
}
