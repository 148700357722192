.o-top-article{

	@media #{$from-small}{
		.a-post__title{ font-size: $font-xl; line-height: 29px }
	}

	picture,img{ width: 100% }

	.a-post__image:before{ display: none; }


}