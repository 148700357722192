.o-top-video{

	@media #{$from-small}{ margin-left: 0; margin-right: 0 }

	picture,img{ width: 100% }

	.a-post__image{
		border-top: none; border-bottom: solid 6px var(--theme);

		.Video__poster{
			position: relative;
			&:after{ content: ''; padding-top: 58.4%; display: block }

			& > img, & > picture{ position: absolute; left: 0; top: 3px; width: 100%; height: 100% }
			img{ object-fit: cover }
		}

		&:before{ display: none }
	}

	.a-post__title{ font-size: $font-xl; line-height: 1.1 }
}