videoelement{ display: none }
.embed-responsive-16by9 {
    height: 100%;
}

.Video{
	cursor: pointer;
    aspect-ratio: 16/9;
	&__legend{ position: overlay(); width: 83.333%; margin-left: 8.33334%; z-index: 2 }
	video{ display: block; width: 100% }

	&--youtube{
		iframe{ position: overlay(); width: 100%; height: 100%; background: #000 }
	}

	&__poster{
		position: relative;
	}

}
